import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const title = 'The Dance Halls';

const article = `
# The Dance Halls
### _Flann O'Brien_

"Jazz-hall' dancing may appear to be a harmless enough business, however unimaginative. Many of our clergy do not think it is and several of the Solomons of the district court are quite certain that it isn't. The dance hall was unknown before the last war and the prevalence of the craze to-day may be taken to be a symptom of a general social change that is bound up with altering conditions of living and working. To-day there are roughly 1,200 licensed halls in the 26 Counties, accounting for perhaps 5,000 dances in a year. Golf and tennis clubs, Volunteer halls and the like do not require a licence. In all, it is a fair guess that 10,000 dances are held in a year, an average of three a day. In terms of time, this means that there is a foxtrot in progress in some corner of Erin's isle throughout the whole of every night and day. This is hard going for a small country. Is it. appalling? Is it true that the rural dance hall is a place to be avoided by our sisters? Is it fair to say that Ireland is peopled by decadent alcoholics in pumps?

In 1935 the Public Dance Halls Act was passed. It was designed to control dancing - by then a vast industry and a country-wide neurosis - and to wipe out abuses bearing on everything from sanitation to immorality. The problem itself and the operation of the Act have occupied pages of newspaper space since. Bishops and judges have made strong comments. Satan has been blamed personally. There is, however, no great uniformity in outlook or conclusion. Here are some reported pronouncements, picked more or less at random. Probably very few of the pronouncers have ever paid fourpence to swelter for four hours in an insanitary shack.

> Some time ago the evil of commercialised dance halls was so great that the Government felt bound to legislate. This legislation has been a dismal farce. It is the opinion of many that it has done more harrn than good. - Most Rev. Dr. Gilmartin.

> The Act was the most excellent passed since 1922. It only required a very modest quota of sweet reasonableness and goodwill to work it. - District Justice Johnson.

> The provisions of the Public Dance Halls Act, 1935, were unworkable and had been recognised by the Bench as unworkable. - District Justice Little.

> There is one agency which Satan has set up here and there in recent years that does incalculably more harm than all the others we have mentioned. It deserves to be called after his name, for he seems to preside at some of the dark rites enacted there. We have in mind the rural dance hall, owned by a private individual, run for profit, open to all who pay, without any exception of persons, conducted with no sort of responsible supervision. - Most Rev. Dr. Morrisroe.

> He saw no harm in a well-conducted dance. It was his wish that people in Raphoe Diocese should get as much innocent recreation as possible and make the most of their opportunities. For that reason he was very pleased to see such a fine new hall being provided in Letterkenny. - Most Rev. Dr. MacNeely.

> They were disposed to think that if the dance finished at midnight on a fine night, people would not be inclined to go home but would walk around the country. - Solicitor Lisdoonvarna Court.

> All-night dancing should be abolished completely. No licence should be granted to a hall which is contiguous to or within easy distance of a public house.* - Rev. R. S. Devane, S.J. (* But is there any such spot in all Ireland?)

> In order to satisfy himself as to the suitability of a place proposed for public dancing, he would require that elevations and sections of the premises, accompanied by a block plan showing the position in relation to adjacent buildings, be lodged with the chief clerk of the court, and that certified copies be supplied to the Superintendent of the division concemed. - District Justice Little.

> The Galway Hospital Committee have passed a resolution drawing attention to the prevalence of sexual immorality as shown by the number of illegitimate births in the Galway Maternity Hospital and stating that it deplores this departure from the Gaelic tradition of purity, caused, in their opinion, by the lessening of parental control and want.of supervision at dances and other amusements. - "Irish Independent.'

> I am in position to state that the vast majority of unmarried mothers have met with downfall under circumstances remote from dance halls or dances. - Dr. J. F. O'Connor, Macroom.

> Illegitimate births in the 26 counties, 1929: 1,853; I939: 1,781. - Returns of the Registrar-General.

> Father Devane is anxious to know how far dance halls set up a restlessness that causes girls to emigrate. He wonders, too, how far dance halls lessen "the strenuous efforts so vitally necessary in the present agricultural crisis.' He asks why there are so many dance halls in Donegal. You follow Father Devane with your coat off. You ask no questions. You tie up dance halls and emigration and make the dance hall nearly as all-embracing a source of evil as the British connection, and hint darkly, . . . There is a background to the dance hall. Take Donegal, the black spot. Social life in the crowded areas there is a grand affair. Dancing played its part. During the winter there were endless excuses for raffles - a dance till midnight at 3d a head. The schoolhouse was used for big nights. The parochial hall was there, but there was always too much style - and the charge was high "to keep out the rough'. The raffle and the schoolhouse are no more. The parochial hall is still a pain in the neck. So the local dance hall arises. It does not come in. It grows. Nothing so natural to these areas is bad. To be sure, "nothing human is perfect'. The men who built these halls are just neighbours to the boys and girls who attend them. The girls don't emigrate in search of dancing or glitter, but in search of wages. Many Donegal girls come to Dublin. Some get quite good wages. But they don't stay in Dublin, although Dublin has dance halls galore. They feel lonely in Dublin, so they go to Glasgow where hundreds of neighbours have made their homes . . . - Peadar O'Donnell in a letter to the "Irish Press'.

> An application on behalf of Rev. Fr. Monaghan, C.C., Killanny, was made for extension of a licence for dances at a carnival.

> Justice Goff - This is a marquee. I am afraid I cannot. - "Irish Independent'.

> When District Justice Walsh was told that a dance licence for a Milford hall was lapsing as the hall was being converted into a cinema, he said: "That is worse. At least the dancing money is kept in the country.' "Irish Independent'.

Let us visit some of these "vestibules of hell'. If you want to go to a dance in the country, you buy a copy of the local paper and turn to the dance page. This, so to speak, is the countryman's leader page. Only after reading every word of it does he penetrate to the coursing notes and after that a long time may elapse before he reaches the customary holocaust involving those 10,000 Chinese coolies.
Judging rural Ireland by these dance blurbs, one would imagine that the entire population are returned emigrants who spent their lives in the neon-spangled honky-tonks of the tough San Francisco waterfront. However congested the district it is one long list of dances, monster dances, grand annual dances, stupendous carnival dances, gala dances, cinderellas, excuse-me's, even an odd "Irish and Old Time' for the cranks. Only the odd name of man or place gives a pathetic clue that there is some makebelieve in progress and that the newspaper is not one of the English language sheets of abandoned Shanghai. You are asked to dance out the Old and dance in the New "to the haunting strains of Mulvaney's Rhythmic Swingsters.' What do you make of "Farmers annual dance (cocktail bar)'? Personally, I see no reason why our ruined farmers should not wear tails if they want to, but they have been misled if they think it is necessary to forsake good whiskey or beer for lethal noggins of chemical gin. "Lime, flood and spot lighting installed for the occasion by the Strand Electric Co., Dublin. Carnival Hats, Novelties, and Gifts for Everyone. A Night of Laughter, Gaiety, Fun and Surprise.' Where? In TUBBERCURRY! This notice appears in the paper upside down and the reader is finally warned that the dance "will be as crazy as this advertisement.' At the Mayo Mental Hospital Staff Dance "the floor will be :specially treated for the occasion,' while that other dance in Fethard will be in aid of "noteworthy object.'

Taking rural Ireland to include the towns, there are three or four kinds of dances. For any dance costing over five shillings, you must put on what is known as "immaculate evening dress.' You are on the border-line when you come down to 3/9. A surprising number of young men own a passable dress-suit and work-a-day rags, with absolutely nothing in between except football attire. I have heard of dancing men being married in their evening clothes.

The dress dance in the country is run by those of the white collar and the white soft hand - clerks, merchants, doctors - and is usually taken locally to be a proof of progress or culture. When the plain people see handsome men in "immaculate' evening clothes alighting from fine motor-cars and disappearing into a Town Hall that seems temporarily glorious and re-born, they know well from their cinema that there is city devilry afoot. Inside, however, the scene is very familiar. Think of an ordinary good dance (say in the European quarter of Shanghai) and then divide everything by two. The lighting is poor and the place is too hot. The floor is of thick planks (it was put there to accommodate a welcome for Parnell) and the knots will tell through the city man's shoddy pumps. The band may be good or bad. Bands vary enormously for this reason - a dance is regarded as successful according to the distance the band has to travel. For the best possible dance the band would have to come from India. This is the great immutable law that determines the local prestige of every event. A Committee is doing pretty well if they can get a band from a hundred miles away. What is regarded as a good band in the country will have "own electric amplification' but may lack a piano. Their tunes will be old and grey and far behind the whistling repertoire of any diligent cinemagoer. "Good-night, Sweetheart' is still a rage in the west.

Nearly every male who goes to dances likes drink and takes plenty of it. Some people may think this is an offensive statement but it is the plain truth. It is often a case of little by little. There is no evil intention. It starts with a few half-ones merely to get into form and after that the reveller is on his way, even if he doesn't know where he is going. This part of the evening's work is performed in an adjacent hotel or pub. Nearly every pub is entitled to serve a toothful to "travellers' but in practice very few of the locals fail to obtain suitable filling for their teeth. It is an old custom.

This custom carries with it an odd accomplishment that no stranger can acquire. It is the craft of going out for twenty separate drinks to a pub 400 yards away without ever appearing to have left the hall at all. It is a waste of time seeking to solve this puzzle by observation. If you are a lady, you can dance every dance with the one gentleman, talk to him unremittingly in the intervals and yet you will notice him getting gayer and gayer from his intermittent but imperceptible absences. If, on the other hand, you are a man who is seated in the pub all night concerned only with honest drinking, you will observe the complementary miracle and wonder how the inebriate in tails manages to satisfy all the requirements of his partner in the hall without ever appearing to leave the pub. There it is. I can offer no explanation.

These dress dances are not very interesting. They have scarcely any relation to "the dance hall scandal,' "the jazz mania,' or any other popular explanation of the decay of our country at the present time. The real thing is the cheap dance where the price of admission ranges from 3d. to 1/6. To arrive at some idea of this, you must divide that recollection of Shanghai, not by two, but by high numbers that go higher as the price comes down. Most of the halls I have seen are old school-houses or new timber structures with a tin roof. There is no means of ventilation save the savage and heroic expedient of the open window. There is no attempt at having a proper dance floor, even where the hall has been built ad hoc. Light is provided by large paraffin lamps suspended from the roof, less frequently by incandescent paraffin installations on the walls. The music may be supplied by a solitary melodeon or piano-accordion, with possibly a fiddle and drums. Dance music as such is almost unknown. What seem to be vague recollections of Irish airs are churned out in an interminable repetition and nearly always bashed into a desultory three-four time that usually sounds very alien to what was intended by our ancestors. Even when a modern dance tune is attempted, it is played straight with no attempt at syncopation, and, being necessarily played from hazy memory, it sometimes finds itself mysteriously transformed into "Terence's Farewell to Kathleen.'

The dancing itself is of the most perfunctory order. If the hall is small and the crowd enormous (and this is the normal situation) the parties quickly lock themselves into a solid mass and keep shuffling and sweating for ten minutes in the space of a square foot, like a vast human centipede marking time. If the hall is roomy and the crowd small, the dancers shuffle about in great circles and can travel a considerable distance in the course of an evening. If a lad cycles twenty miles to a dance and twenty miles home and does another ten miles in the hall, he is clearly in earnest about his dancing.

Just as the success of a dear dance depends on the extraterritoriality of the band, no cheap dance can be said to have succeeded if the door of the hall can be readily opened from without after the first half-hour. The crowd inside must be so dense that an entire re-packing and re-arrangement of the patrons is necessary before even the blade of a knife could be inserted through the door. When you do enter, you find yourself in air of the kind that blurts out on you from an oven when you open it. All about you is an impenetrable blue tobacco haze that is sometimes charged with a palpable fine filth beaten up out of the floor. Whether standing or dancing, the patrons are all i bhfastodh (Editor: - i.e., "in a clinch') on each other like cows in a cattle truck, exuding sweat in rivers and enjoying themselves immensely. Nobody is self-conscious about sweat. It rises profusely in invisible vapour from all and sundry and there is no guarantee that each cloud will condense on its true owner.

There are certain general considerations which apply to all these dances. The girls always predominate and usually pay their own way. Behind the upright throng one can sometimes glimpse a low flat row of sitting people who look as if they were painted or pasted on the wall. Once there, they seem to have no chance of budging till the dance is over. There is always an official charged with accomplishing a hospitable rite known as "looking after the band.' Late in the night there are signs on him that he has been looking after himself, but the boisterous revival of the "musicianers' towards the end of the evening will prove that he has not made undue depredations on the trust fund. For a dance of any importance (say, a shilling dance) the average farmer's son will permit himself a half-pint of "parliamentary whiskey' on the hip and will not hesitate to lace his blood with judicious nips of poteen if the stuff is to be had. The liquor is consumed in breath-taking gulps in a place apart and is never openly flaunted. Scenes of open or riotous drunkenness are rare. Notwithstanding any cloakroom that may be provided, complete with attendant, the general tendency is to bring the overcoat into the hall. No tax is payable on any dance that costs anything up to 4d. Tax and licensing provisions are evaded by calling a short dance a "practice' or a "dance class.' Where supper is provided, the beverage is always tea, never a nourishing or cooling draught like home-made lemonade. If a committee can rise to the swirling device that throws pretty and romantic coloured lights throughout the darkened hall, they are entitled to call their functions "gala dances.' There are fine big halls here and there where dancing is attempted on a, reasonable basis - the big converted railway shops at Dundalk, for instance, or the shirt factory building outside Buncrana.

Irish dancing is a thing apart. There is perhaps one céilidhe held for every twenty dances. The foxtrot and the Fairy Reel are mutually repugnant and will not easily dwell under the same roof. Very few adherents of the "ballroom' canon will have anything, to do with a jig or a reel. Apart from the fact that the Irish dance is ruled out in most halls by considerations of space or perspiration, there is a real psychological obstacle. It is a very far cry from the multiple adhesion of enchanted country stomachs in a twilight of coloured bulbs to the impersonal free-for-all of a clattering reel. Irish dancing is emotionally cold, unromantic and always well-lighted.

One occasionally encounters the barn-dance. This is the "mind the dresser' business held to the tune of a lone Raftery in a farmhouse kitchen, to celebrate a wedding or an American wake. Most of the dances are sets and half-sets based on English figure dances and introduced to this country in the seventeenth and eighteenth centuries by the landlord class. There are also boisterous versions of Irish dances and an odd invitation to talented individuals to "oblige' with solo items. Curiously enough, this sort of thing is beginning to smell of the stage Irishman.

Some district justices have a habit of taking leave of their senses at the annual licensing sessions. They want Irish dancing and plenty of it, even at the most monster "gala dance.' They believe that Satan with all his guile is baffled by a four-hand reel and cannot make head or tail of the Rakes of Mallow. I do not think that there is any real ground for regarding Irish dancing as a sovereign spiritual and nationalistic prophylactic. If there is, heaven help the defenceless nations of other lands.

Little need be said about dancing in Dublin or Cork. In Cork nearly all the fun is concentrated in the spacious and well-run Arcadia. Dublin has six or seven hotel-halls where the all-important "refreshment' facilities are available and some sixty other halls where there is dancing several nights a week. In addition, every junta of good-time folk who care to register themselves as a sports or social club can dance and drink almost without restriction. In theory, only members can join in this diversion but in practice anybody can pay and enter. At 1 p.m. on Sunday morning these haunts are crowded with ill-slept revellers in search of healing ale.

Yes, strange and beautiful sights you will see at a dance in Dublin. Even a district justice, happy and mum, surrounding with his righteous tissue a sizzling tank of malt. That dance, however, does not cost threepence. The entry-fee is 7/6 or half a legal guinea.
`;

const DanceHalls = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - {title}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{title}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default DanceHalls;
